<template>
  <section id="pending-cases-view" class="case-creation" v-loading="loading">
    <div class="navbarComponent">
      <div class="container-sm">
        <div class="inside">
          <div class="case-status" v-if="this.caseInfo && this.caseInfo.status">
            <ul class="justify-content-between">
              <li>
                <el-button
                  class="btn-delete"
                  style="cursor: pointer"
                  @click="goToEditForm"
                  v-if="!isCaseCompleted && hasPermission('cases', 'edit')"
                >
                  <span class="pr-5">EDIT</span>
                </el-button>
              </li>
              <li>
                <ul>
                  <li>
                    <div
                      v-if="this.caseInfo.accession_id && this.caseInfo.case_id"
                    >
                      <h4 class="accession-id">
                        Accession Id :
                        <router-link
                          class="accession-id-router"
                          :to="{
                            name: 'CaseView',
                            params: { case_id: this.caseInfo.case_id },
                            query: this.$route.query,
                          }"
                          >{{ this.caseInfo.accession_id }}</router-link
                        >
                      </h4>
                    </div>

                    <div
                      v-if="hasPermission('pending_genetic_cases', 'convert')"
                    >
                      <el-button
                        class="icon-btn"
                        v-if="
                          this.caseInfo.status != 'COMPLETE' &&
                          this.caseInfo.status != 'REJECTED'
                        "
                        @click="askReason = true"
                      >
                        <img
                          src="@/assets/images/icons/actions/convert-to-case-white.svg"
                          alt="icon"
                        />
                        Convert To Case</el-button
                      >
                    </div>
                  </li>
                  <li>
                    <h3 class="name">Case Status</h3>
                  </li>
                  <li>
                    <el-button
                      type="success"
                      size="medium"
                      class="px-010"
                      :class="this.caseInfo.status"
                    >
                      <span class="pr-5" v-if="caseInfo.status == 'COMPLETE'"
                        >APPROVED</span
                      >
                      <span class="pr-5" v-else-if="caseInfo.status == 'ACTIVE'"
                        >PENDING</span
                      >
                      <span
                        class="pr-5"
                        v-else-if="caseInfo.status == 'INITIATE'"
                        >PENDING</span
                      >
                      <span class="pr-5" v-else>{{
                        this.caseInfo.status.split("_").join(" ")
                      }}</span>
                      <i class="el-icon-success"></i>
                    </el-button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="case-creation__step case-creation__patient-information">
      <div class="container-sm">
        <div class="case-creation__card">
          <div class="case-creation__heading edit-view">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-patient-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Patient Information</h3>
            </div>
          </div>

          <div class="case-creation__body pb-0">
            <div class="vue-data" v-if="getPatientInformation">
              <el-row :gutter="20">
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">First Name</h4>
                    <p class="value" v-if="getPatientInformation.first_name">
                      {{ getPatientInformation.first_name }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">middle Name</h4>
                    <p class="value" v-if="getPatientInformation.middle_name">
                      {{ getPatientInformation.middle_name }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Last Name</h4>
                    <p class="value" v-if="getPatientInformation.last_name">
                      {{ getPatientInformation.last_name }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Gender</h4>
                    <p class="value" v-if="getPatientInformation.gender">
                      {{ getPatientInformation.gender }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Date of Birth</h4>
                    <p class="value" v-if="getPatientInformation.date_of_birth">
                      {{ formatDate(getPatientInformation.date_of_birth) }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>

                <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Email</h4>
                    <p class="value" v-if="getPatientInformation.email">
                      {{ getPatientInformation.email }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">EMR</h4>
                    <p class="value" v-if="getPatientInformation.emr">
                      {{ getPatientInformation.emr }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Mobile Number</h4>
                    <p class="value" v-if="getPatientInformation.home_phone">
                      {{ getPatientInformation.home_phone }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Phone Number</h4>
                    <p class="value" v-if="getPatientInformation.work_phone">
                      {{ getPatientInformation.work_phone }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Address Line 1</h4>
                    <p
                      class="value"
                      v-if="getPatientInformation.address_line_1"
                    >
                      {{ getPatientInformation.address_line_1 }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Address Line 2</h4>
                    <p
                      class="value"
                      v-if="getPatientInformation.address_line_2"
                    >
                      {{ getPatientInformation.address_line_2 }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Zip</h4>
                    <p class="value" v-if="getPatientInformation.zip">
                      {{ getPatientInformation.zip }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">State</h4>
                    <p class="value" v-if="getPatientInformation.state">
                      {{ getPatientInformation.state }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">County</h4>
                    <p class="value" v-if="getPatientInformation.county">
                      {{ getPatientInformation.county }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">City</h4>
                    <p class="value" v-if="getPatientInformation.city">
                      {{ getPatientInformation.city }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Race</h4>
                    <p class="value" v-if="getPatientInformation.race">
                      {{ getPatientInformation.race }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                  <div class="input-value view-cases">
                    <h4 class="name">Ethnicity</h4>
                    <p class="value" v-if="getPatientInformation.ethnicity">
                      {{ getPatientInformation.ethnicity }}
                    </p>
                    <p class="value" v-else>--</p>
                  </div>
                </el-col>
                <el-col
                  :xl="3"
                  :lg="4"
                  :md="8"
                  :sm="12"
                  :xs="12"
                  v-if="getPatientInformation.pregnant"
                >
                  <div class="input-value view-cases">
                    <h4 class="name">Pregnancy</h4>
                    <p class="value">
                      {{ getPatientInformation.pregnant ? "Yes" : "No" }}
                    </p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>

        <!-- Case Information start here -->
        <span class="case-creation__divider"></span>
        <div class="case-creation__card">
          <div class="case-creation__heading edit-view">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-case-info.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Case Information</h3>
            </div>
          </div>
          <div
            class="case-creation__body pb-0"
            v-if="
              getsinglePatientDetails &&
              Object.keys(getsinglePatientDetails).length
            "
          >
            <el-row :gutter="15" class="vue-data">
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Case Type</h4>
                  <ul>
                    <li
                      v-for="(
                        caseType, index
                      ) of getsinglePatientDetails.case_types"
                      :key="index"
                    >
                      {{ caseTypeMap[caseType] }}
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Laboratory</h4>
                  <p class="value" v-if="getsinglePatientDetails.lab">
                    {{ getsinglePatientDetails.lab.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Collection Date</h4>
                  <p class="value">
                    {{
                      momentWithTimezoneTime(
                        getsinglePatientDetails.collection_date,
                        "MM-DD-yyyy hh:mm A"
                      )
                    }}
                  </p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Received Date</h4>
                  <p class="value">
                    {{
                      momentWithTimezoneTime(
                        getsinglePatientDetails.received_date,
                        "MM-DD-yyyy hh:mm A"
                      )
                    }}
                  </p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Bill To</h4>
                  <p class="value" v-if="getsinglePatientDetails.billing_type">
                    {{ getsinglePatientDetails.billing_type }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Order ID</h4>
                  <p class="value" v-if="getsinglePatientDetails.order_id">
                    {{ getsinglePatientDetails.order_id }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Ordering Facility</h4>
                  <p class="value" v-if="getsinglePatientDetails.hospital">
                    {{ getsinglePatientDetails.hospital.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Ordering Facility Branch</h4>
                  <p
                    class="value"
                    v-if="getsinglePatientDetails.hospital_branch"
                  >
                    {{ getsinglePatientDetails.hospital_branch.display_name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Ordering Physician</h4>
                  <p
                    class="value"
                    v-if="getsinglePatientDetails.ordering_physician"
                  >
                    {{ getsinglePatientDetails.ordering_physician.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>

              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Referring Physician</h4>
                  <p
                    class="value"
                    v-if="getsinglePatientDetails.referring_physician"
                  >
                    {{ getsinglePatientDetails.referring_physician.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Specimen Collector</h4>
                  <p
                    class="value"
                    v-if="getsinglePatientDetails.specimen_collector"
                  >
                    {{ getsinglePatientDetails.specimen_collector }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>

              <el-col :xl="12" :lg="12" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases icd-codes">
                  <div class="indicatives">
                    <h4 class="name">ICD-10 (Indicative)</h4>
                    <ul>
                      <li
                        class="value"
                        v-for="(
                          icd_10_code, index
                        ) in getsinglePatientDetails.icd_10_codes"
                        :key="index"
                      >
                        {{ icd_10_code }}
                      </li>
                    </ul>
                  </div>
                </div>
              </el-col>
              <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Additional Fax Receiver Name</h4>
                  <p
                    class="value"
                    v-if="getsinglePatientDetails.additional_fax_requisite"
                  >
                    {{ getsinglePatientDetails.additional_fax_requisite }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>

              <el-col :xl="16" :lg="8" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">Additional Fax</h4>
                  <ul
                    class="value"
                    v-if="
                      getsinglePatientDetails.additional_fax &&
                      getsinglePatientDetails.additional_fax.length
                    "
                  >
                    <li
                      v-for="(
                        fax, index
                      ) in getsinglePatientDetails.additional_fax"
                      :key="index"
                    >
                      <span>
                        {{ fax }}
                      </span>
                    </li>
                  </ul>

                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="12" :md="12">
                <div class="input-value view-cases">
                  <div
                    class="speciment-type-view"
                    v-if="
                      getsinglePatientDetails &&
                      getsinglePatientDetails.sample_types
                    "
                  >
                    <div>
                      <h4 class="name">Specimen Types</h4>
                      <ul
                        class="case_type"
                        v-if="
                          getsinglePatientDetails.sample_types &&
                          getsinglePatientDetails.sample_types.length
                        "
                      >
                        <li
                          class="value"
                          v-for="(
                            sampleType, index
                          ) in getsinglePatientDetails.sample_types"
                          :key="index"
                        >
                          {{ caseTypeMap[sampleType.case_type] }}
                        </li>
                      </ul>
                      <ul v-else>
                        --
                      </ul>
                    </div>
                    <div>
                      <h4 class="name">&nbsp;</h4>

                      <ul
                        class="speciment_type"
                        v-if="
                          getsinglePatientDetails.sample_types &&
                          getsinglePatientDetails.sample_types.length
                        "
                      >
                        <li
                          v-for="(
                            sampleType, index
                          ) in getsinglePatientDetails.sample_types"
                          :key="index"
                        >
                          <span
                            v-if="
                              sampleType.sample_types &&
                              sampleType.sample_types.length
                            "
                          >
                            <span
                              v-for="(sample, index) in sampleType.sample_types"
                              :key="index"
                            >
                              {{ sample }}
                              <span
                                v-if="
                                  index + 1 < sampleType.sample_types.length
                                "
                                >,</span
                              >
                            </span>
                          </span>
                          <span v-else> -- </span>
                        </li>
                      </ul>
                      <ul v-else>
                        --
                      </ul>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col
                :xl="6"
                :lg="12"
                :md="12"
                :sm="12"
                :xs="12"
                v-if="!isBillingUser"
              >
                <div class="input-value view-cases cpt-codes">
                  <div class="indicatives">
                    <h4 class="name">CPT Codes</h4>
                    <ul
                      v-if="
                        getsinglePatientDetails &&
                        getsinglePatientDetails.cpt_codes &&
                        getsinglePatientDetails.cpt_codes.length
                      "
                    >
                      <li
                        class="value"
                        v-for="(
                          cpt_code, index
                        ) in getsinglePatientDetails.cpt_codes"
                        :key="index"
                      >
                        {{ cpt_code || "--" }}
                      </li>
                    </ul>
                    <ul v-else>
                      --
                    </ul>
                  </div>
                </div>
              </el-col>
              <el-col
                :xl="3"
                :lg="4"
                :md="8"
                :sm="12"
                :xs="12"
                v-if="hadClinicalChemistry"
              >
                <div class="input-value view-cases">
                  <h4 class="name">Fasting</h4>
                  <p class="value">
                    {{ getsinglePatientDetails.is_fasting ? "Yes" : "No" }}
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- Test information-->
        <span class="case-creation__divider"></span>
        <TestInformation></TestInformation>
        <div v-if="isCaseHavingAdditionalNotes">
          <AdditionalNotes></AdditionalNotes>
        </div>
        <span class="case-creation__divider"></span>
        <MedicationView></MedicationView>
        <div v-if="hasBillingType">
          <BillingInformation></BillingInformation>
        </div>
        <span v-if="show" class="case-creation__divider"></span>
        <div v-if="show">
          <TestAttachments></TestAttachments>
          <span class="case-creation__divider"></span>
        </div>
        <span class="case-creation__divider"></span>
        <div class="default-modal" v-if="askReason">
          <el-dialog
            width="30%"
            v-model="askReason"
            title="Convert to case"
            :before-close="handleClose"
          >
            <div class="dialog-header">
              <span class="header-part"> Convert to case </span>
              <el-button @click="handleClose">
                <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
              /></el-button>
            </div>
            <div class="popup-date">
              <el-form
                :model="caseForm"
                ref="convertionForm"
                :rules="caseRules"
              >
                <el-form-item prop="reasonForConvertion">
                  <el-input
                    type="textarea"
                    placeholder="Please enter reason to convert "
                    v-model="caseForm.reasonForConvertion"
                  ></el-input>
                  <p>
                    <b>Note:</b> Minimum 3 characters is required
                  </p></el-form-item
                >
              </el-form>
              <div class="pt-5" style="color: #273480">
                <span style="font-weight: 500"> Approved By: </span>
                <span style="font-weight: 500"
                  >{{
                    getAuthenticatedUser.first_name +
                    " " +
                    getAuthenticatedUser.last_name
                  }}
                </span>
              </div>

              <div class="dialog-footer">
                <el-button class="cancel-button" @click="handleClose"
                  >Cancel</el-button
                >
                <el-button
                  class="blue-save-button"
                  :disabled="checkReasonForConvertion"
                  @click="convertToRegularCase('convertionForm')"
                  :loading="loading"
                  >Submit</el-button
                >
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { defineAsyncComponent } from "vue";
const TestInformation = defineAsyncComponent(() =>
  import("@/components/pendingCases/ViewTests")
);
const MedicationView = defineAsyncComponent(() =>
  import("@/components/pendingCases/MedicationView")
);
const BillingInformation = defineAsyncComponent(() =>
  import("@/components/pendingCases/BillingInformation")
);
const TestAttachments = defineAsyncComponent(() =>
  import("@/components/pendingCases/TestAttachments")
);
const AdditionalNotes = defineAsyncComponent(() =>
  import("@/components/pendingCases/AdditionalNotes")
);
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import caseConstants from "@/config/constants/caseConstants";
export default {
  components: {
    TestInformation,
    MedicationView,
    BillingInformation,
    TestAttachments,
    AdditionalNotes,
  },
  data() {
    return {
      scrollPosition: null,
      loading: false,
      askReason: false,
      caseForm: {
        reasonForConvertion: "",
      },
      caseRules: {
        reasonForConvertion: [
          {
            required: true,
            message: "Invalid  reason",
            pattern: /^.\S/,
            trigger: "blur",
          },
        ],
      },
      show: false,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      showTests: false,
    };
  },
  mixins: [UserPermissionsHelper],
  computed: {
    Reason() {
      if (
        this.caseForm.reasonForConvertion &&
        this.caseForm.reasonForConvertion.length >= 3
      ) {
        return true;
      }

      return false;
    },
    ...mapGetters("pendingCases", [
      "getsinglePatientDetails",
      "getMedication",
      "getCaseConvertionStatus",
    ]),
    checkReasonForConvertion() {
      if (
        this.caseForm.reasonForConvertion &&
        this.caseForm.reasonForConvertion.trim().length >= 3
      ) {
        return false;
      }

      return true;
    },
    caseInfo() {
      return this.getsinglePatientDetails
        ? JSON.parse(JSON.stringify(this.getsinglePatientDetails))
        : null;
    },
    isCaseCompleted() {
      if (!this.getsinglePatientDetails) {
        return false;
      }
      return (
        this.getsinglePatientDetails.status === "COMPLETE" ||
        this.getsinglePatientDetails.status === "REJECTED"
      );
    },
    getPatientInformation() {
      if (
        this.getsinglePatientDetails &&
        Object.keys(this.getsinglePatientDetails).length > 0 &&
        this.getsinglePatientDetails.patient_info
      ) {
        return this.getsinglePatientDetails.patient_info;
      }
      return null;
    },
    caseId() {
      if (this.$route.params && this.$route.params.case_id) {
        return this.$route.params.case_id;
      }
      this.isToxicology();
      return null;
    },
    hasBillingType() {
      if (
        this.getsinglePatientDetails &&
        this.getsinglePatientDetails.billing_type &&
        this.getsinglePatientDetails.billing_type == "INSURANCE"
      ) {
        return true;
      }
      return false;
    },
    isCaseHavingAdditionalNotes() {
      if (
        this.getsinglePatientDetails &&
        this.getsinglePatientDetails.physician_notes
      ) {
        return true;
      }
      return false;
    },
    hadClinicalChemistry() {
      if (
        this.getsinglePatientDetails &&
        this.getsinglePatientDetails.case_types &&
        this.getsinglePatientDetails.case_types.indexOf("CLINICAL_CHEMISTRY") >
          -1
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.show = true;
    await this.getCaseData();
    this.showTests = true;
    // Add Class to body on scroll
    window.addEventListener("scroll", this.onScroll);
  },
  async unmounted() {
    // Remove Class to body on scroll
    document.body.classList.remove("fixedNavbarOnComponent");
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    convertToRegularCase(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.convertingToRegularCase();
        } else {
          return false;
        }
      });
    },
    async onScroll() {
      // Get the scroll position, you can adjust this value based on your requirement
      const scrollPosition = window.scrollY;
      // Add a class to the body element based on the scroll position
      // For example, adding a class 'scrolled' when the user scrolls more than 100px
      if (scrollPosition > 1) {
        document.body.classList.add("fixedNavbarOnComponent");
      } else {
        document.body.classList.remove("fixedNavbarOnComponent");
      }
    },
    async getCaseData() {
      if (this.caseId) {
        await this.$store.dispatch("pendingCases/fetchSingleCaseInformation", {
          case_id: this.caseId,
        });
      }
    },
    goToEditForm() {
      this.$router.push({
        name: "PendingCasesEdit",
        params: { case_id: this.caseId },
        query: this.$route.query,
      });
    },
    isToxicology() {
      let case_types =
        (this.caseInfo &&
          this.caseInfo.case_types &&
          this.caseInfo.case_types) ||
        [];
      this.showMeditation =
        case_types.indexOf("TOXICOLOGY") != -1 ? true : false;
    },
    handleClose() {
      this.askReason = false;
      this.caseForm.reasonForConvertion = "";
    },
    async convertingToRegularCase() {
      this.loading = true;

      let params = {
        converting_reason: this.caseForm.reasonForConvertion,
        case_id: this.caseId,
      };
      await this.$store.dispatch("pendingCases/convertToCase", params);
      if (this.getCaseConvertionStatus) {
        this.$notify({
          type: "success",
          title: "Success",
          message: this.getCaseConvertionStatus.message,
        });
        await this.getCaseData(1);
        this.askReason = false;
        window.location.reload();
      }
      this.loading = false;
    },
    async toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
</script>
<style lang="scss">
#pending-cases-view {
  .case-status {
    li {
      button {
        cursor: default;
      }
    }
  }
  .cpt-codes {
    .indicatives {
      ul {
        display: flex;
        row-gap: 10px;
        li {
          margin: 0 5px;
          width: 45px;
          text-align: center;
          p {
            .value {
              padding: 3px 12px !important;
            }
          }
        }
      }
    }
  }
}
</style>
